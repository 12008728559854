import {Observable, Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {MenuCompositionDTO} from '../../dtos/menucomposition-dto';
import {UtilsService} from '../../utils/utils.service';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import {MenusRoutemapService} from './menus-routemap.service';
import {ProduitDeclinaisonDTO} from '../../dtos/produit-declinaison-dto';
import {ProduitRouteMapService} from '../gestionproduits/produit-routemap.service';
import {FamilleNutritionnelleProduitDTO} from '../../dtos/famillenutritionelle-produit-dto';
import {FamilleGemrcnDTO} from '../../dtos/famille-gemrcn-dto';

import {FicheTechniqueLigneDTO} from '../../dtos/fiche-technique-ligne-dto';
import {CouleurDTO} from '../../dtos/couleur-dto';
import {MenuDecoupageDateDTO} from '../../dtos/menudecoupagedate-dto';
import {ContratMenuConviveDecoupageDTO} from '../../dtos/contratmenuconvivedecoupage-dto';
import {DialogRecherchePlatsSupplier} from './menus-planning2.service';
import {HttpService} from '../technique/http.service';
import {CelluleTableauDTO} from "../../dtos/gestionmenus/planning/cellule-tableau-dto";

export const URL_POST_FIND_INGREDIENTS = `dolrest/gestionmenus2/ingredients`;
export const URL_POST_SAVE_MENUS_COMPOS = `dolrest/gestionmenus2/saveMenusCompositions`;
export const URL_POST_SAVE_MENU_COMPO = `dolrest/gestionmenus2/saveMenuComposition`;
export const URL_POST_MOVE_MENU_COMPO = `dolrest/gestionmenus2/moveMenuComposition`;
export const URL_POST_PERMUTE_MENUS_COMPOS = `dolrest/gestionmenus2/permuteMenusCompositions`;

@Injectable()
export class MenusCompositionService {


  private subjectProduitsDeclinaisonsFromSearch = new Subject<DialogRecherchePlatsSupplier>();
  produitsDeclinaisonsFromSearch$ = this.subjectProduitsDeclinaisonsFromSearch.asObservable();



  private subjectRoutingSidebar = new Subject<any>();
  routingSidebar$ = this.subjectRoutingSidebar.asObservable();

  constructor(private httpSvc: HttpService,
              private produitRoutemapSvc: ProduitRouteMapService,
              private menuRoutemapSvc: MenusRoutemapService,
              private utils: UtilsService) {
  }



  moveMenuComposition = (source: MenuCompositionDTO, destination: MenuCompositionDTO) => {

    const fd = new FormData();
    fd.set('jsonSource', JSON.stringify(source));
    fd.set('jsonDestination', JSON.stringify(destination));

    return this.httpSvc.post(URL_POST_MOVE_MENU_COMPO, fd);

  };

  /**
   * Enregistrer ou mettre à jour un menu composition
   * @param {MenuCompositionDTO} menuComposition
   * @returns {Observable<ResponseWrapper<MenuCompositionDTO>>}
   */
  saveMenuComposition = (menuComposition: MenuCompositionDTO) => {

    if (menuComposition) {
      return this.httpSvc.post(URL_POST_SAVE_MENU_COMPO, menuComposition);
    }
    return this.utils.handleResponseError('Menu composition non défini', true);
  };

  /**
   * Enregistrer ou mettre à jour des menus compositions
   * @param {MenuCompositionDTO} menuComposition
   * @returns {Observable<ResponseWrapper<MenuCompositionDTO>>}
   */
  saveMenusCompositions = (menusCompos: MenuCompositionDTO[]): Observable<ResponseWrapper<MenuCompositionDTO>> => {

    if (!this.utils.isCollectionNullOrEmpty(menusCompos)) {
      return this.httpSvc.post(URL_POST_SAVE_MENUS_COMPOS, menusCompos);
    }
    return this.utils.handleResponseError('Menu composition non défini', true);
  };

  /**
   * Récupérer les ingrédients d'un menu compo
   * @param {MenuCompositionDTO} menuComposition
   * @returns {Observable<ResponseWrapper<MenuCompositionDTO>>}
   */
  getIngredients = (idMenuCompo: number): Observable<ResponseWrapper<FicheTechniqueLigneDTO>> => {

    if (!this.utils.isNullOrEmpty(idMenuCompo)) {

      const fd = new FormData();
      fd.set('idMenuCompo', idMenuCompo + '');

      return this.httpSvc.post(URL_POST_FIND_INGREDIENTS, fd);
    }
    return this.utils.handleResponseError('Menu composition non défini', true);
  };


  getFamilleNutri = (fnp: FamilleNutritionnelleProduitDTO): FamilleGemrcnDTO => {
    let famnut: FamilleGemrcnDTO = new FamilleGemrcnDTO();

    famnut.id = fnp.idFamille;
    famnut.libelle = fnp.familleNutritionnelleLibelle;
    famnut.actif = fnp.familleNutritionnelleActif;

    const couleur = new CouleurDTO();

    couleur.hexa = fnp.familleNutritionnelleCouleurHexa;
    couleur.id = fnp.familleNutritionnelleCouleurId;

    famnut.couleur = couleur;

    return famnut;
  };


  createMenuCompoFromProduitDeclinaison = (produitDeclinaison: ProduitDeclinaisonDTO, ordre: number, dateMenu: Date, idCmcd: number, idMdd: number, idCmcCa: number) => {

    const newMc = new MenuCompositionDTO();
    newMc.id = 0;
    newMc.ordre = ordre;
    newMc.produitDeclinaisonPrixAchat = produitDeclinaison.prixAchat;
    newMc.effectifPrevu = 0;
    newMc.produitDeclinaisonId = produitDeclinaison.id;
    newMc.produitDeclinaisonLibelle = produitDeclinaison.libelle;
    newMc.familleNutritionnelleProduitDTOList = produitDeclinaison.famillesNutritionnellesProduits;
    newMc.produitAllergeneDTOList = produitDeclinaison.produitAllergeneList;
    newMc.cmcContrainteAlimId = idCmcCa;
    newMc.cmcdId = idCmcd;

    const cmcd = new ContratMenuConviveDecoupageDTO();
    cmcd.id = idCmcd;

    const mdd = new MenuDecoupageDateDTO();
    mdd.dateMenu = dateMenu;
    mdd.contratMenuConviveDecoupage = cmcd;
    mdd.id = idMdd;

    newMc.menuDecoupageDate = mdd;


    return newMc;

  };

  announceProduitsDeclinaisonsFromSearch = (searchSupplier: DialogRecherchePlatsSupplier) => {
    this.subjectProduitsDeclinaisonsFromSearch.next(searchSupplier);
  };

  announceRoutingSidebar = (fabrique: boolean, idProduit: number, idSiteContratMenu: number, fromMenuPlanning?: boolean) => {
    this.subjectRoutingSidebar.next({ fabrique, idProduit, idSiteContratMenu, fromMenuPlanning });
  };

  permuteMenusCompositions = (menuCompoSource: MenuCompositionDTO, menuCompoTarget: MenuCompositionDTO) => {

    const fd = new FormData();
    fd.set('jsonMc1', JSON.stringify(menuCompoSource));
    fd.set('jsonMc2', JSON.stringify(menuCompoTarget));

    return this.httpSvc.post(URL_POST_PERMUTE_MENUS_COMPOS, fd);

  };
}
