// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.0-rc-10239(cf138c6e32)-C10/05/2024-15:33:27-B10/05/2024-15:37:23' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.0-rc-10239(cf138c6e32)-C10/05/2024-15:33:27-B10/05/2024-15:37:23",
  branch: "master",
  latestTag: "6.0-rc",
  revCount: "10239",
  shortHash: "cf138c6e32",
  longHash: "cf138c6e32d52d43dfe018ecba48c13f4f790ac1",
  dateCommit: "10/05/2024-15:33:27",
  dateBuild: "10/05/2024-15:37:23",
  buildType: "Ansible",
  } ;
