import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {PlanProductionDTO} from '../../../../core/dtos/plan-production-dto';
import {TreeNode} from 'primeng/api';
import {UniteDeProduction__EquipeDTO} from '../../../../core/dtos/unite-de-production__equipe';
import {UtilsService} from '../../../../core/utils/utils.service';
import * as moment from 'moment';
import {sortBy as _sortBy} from 'lodash'
import {saveAs as fs_saveAs} from 'file-saver';
import {GenericFormService} from '../../../../core/services/generics/generic-form.service';
import {ProductionService, TYPE_JOUR} from '../../../../core/services/gestion-production/production.service';
import {MSG_KEY, MSG_SEVERITY} from '../../../../core/constants';
import {BaseProductionComponent} from '../../../../shared/base-production/base-production.component';
import {ToastService} from "../../../../core/services/technique/toast.service";
import {ResponseWrapper} from "../../../../core/suppliers/wrappers/response-wrapper";
import {ProduitDTO} from "../../../../core/dtos/produit-dto";
import {DxListComponent} from "devextreme-angular";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";


@Component({
  selector: 'yo-print-settings',
  templateUrl: './print-settings.component.html',
  styleUrls: ['./print-settings.component.scss']
})
export class PrintSettingsComponent extends BaseProductionComponent implements OnInit {

  typeJourSelected: string;
  products: any[] = [];
  datasourceProducts: any = [];

  _equipesItems: any;
  equipesSelected: TreeNode[] = [];
  jourObligatoire: string;
  warningGroupingLines: string;
  typesJours: any[] =
    [
      {text: 'Jour de fabrication', value: 'jourFab'},
      {text: 'Jour de consommation', value: 'jourConso'},
    ];
  form: FormGroup;

  orientationPortrait: FICHE_TECHNIQUE_PRINT_MODE = FICHE_TECHNIQUE_PRINT_MODE.PORTRAIT;
  orientationPaysage: FICHE_TECHNIQUE_PRINT_MODE = FICHE_TECHNIQUE_PRINT_MODE.PAYSAGE;

  /**
   * Imprimer uniquement les recettes qui ont des effectifs
   */
  printOnlyWithParts: boolean = false;

  constructor(public utils: UtilsService,
              public domSvc: DomSanitizer,
              private gfs: GenericFormService,
              private productionSvc: ProductionService,
              private toastSvc: ToastService) {
    super();
  }

  _planProd: PlanProductionDTO;

  @Input('planProd') set planProd(value: PlanProductionDTO) {
    this._planProd = value;
    if (this._planProd) {
      this.dialogTitle = `Édition "${this._planProd.libelle}"`
      this.initForm(new PlanProdPrintSupplier());
    }

  }

  @Input('datesConso') set datesConso(values: Date[]) {

    this._datesConso = [];
    if (!this.utils.isCollectionNullOrEmpty(values)) {
      values = _sortBy(values);
      values.map((dateConso, idx) => this._datesConso.push({
        id: `conso${idx}`,
        label: this.utils.getFrenchDate_dddd_Do_MMMM_YYYY(moment(dateConso)),
        value: dateConso
      }));
      this.datasourceDatesConso = new DataSource({
        store: new ArrayStore({
          key: 'id',
          data: this._datesConso,
        }),
        paginate: false
      });
    }

  }

  _datesFab: any[];
  datasourceDatesFab: any;

  @Input('datesFab') set datesFab(values: Date[]) {

    this._datesFab = [];
    if (!this.utils.isCollectionNullOrEmpty(values)) {
      values = _sortBy(values);
      values.map((dateFab, idx) => this._datesFab.push({
        id: idx,
        label: this.utils.getFrenchDate_dddd_Do_MMMM_YYYY(moment(dateFab)),
        value: dateFab
      }));
      this.datasourceDatesFab = new DataSource({
        store: new ArrayStore({
          key: 'id',
          data: this._datesFab,
        }),
        paginate: false
      });
    }
  }

  @Input('equipes') set equipes(values: UniteDeProduction__EquipeDTO[]) {

    this._equipesItems = {data: []};

    // Les items sont classés alphabétiquement suivant
    // - le zoneDeProductionLibelle
    // - le equipeLibelle
    this._equipesItems.data.sort((a: any, b: any) => {
      if (a.label > b.label) {
        return 1;
      }
      if (a.label < b.label) {
        return -1;
      }
      return 0;
    });
  }

  @ViewChild("list", {static: false}) listPlats: DxListComponent;
  @ViewChild("listDatesConso", {static: false}) listDatesConso: DxListComponent;
  @ViewChild("listDatesFab", {static: false}) listDatesFab: DxListComponent;

  initForm = (printSupplier: PlanProdPrintSupplier): void => {
    this.form = new FormGroup({
      typeJour: new FormControl(printSupplier.selectedTypeJour, Validators.required),
      groupByTeam: new FormControl(printSupplier.groupByTeam),
      groupByPlc: new FormControl(printSupplier.groupByPlc),
      groupByMeal: new FormControl(printSupplier.groupByMeal),
      groupByConsumptionDate: new FormControl(printSupplier.groupByConsumptionDate),
      selectedJoursFab: new FormControl(printSupplier.selectedJoursFab),
      selectedJoursConso: new FormControl(printSupplier.selectedJoursConso),
      selectedEquipes: new FormControl(printSupplier.selectedEquipes)
    });
  };

  checkGroupingLines = (): void => {
    if (!this.form.controls['groupByTeam'].value && !this.form.controls['groupByPlc'].value && !this.form.controls['groupByMeal'].value && !this.form.controls['groupByConsumptionDate'].value)
      this.warningGroupingLines = `Veuillez sélectionner au moins un regroupement`;
    else
      this.warningGroupingLines = '';
  }

  ngOnInit() {
    this.initForm(new PlanProdPrintSupplier());
    this.initSelectedJour();
  }

  initSubscriptionFichesTechniques = (): void => {
    if (this._planProd && this._planProd.id) {
      this.productionSvc.getPlanProductionDetailsForFiltersSettingsPrint(this._planProd, this.form, this.listDatesFab?.selectedItems.map(d => d.value),
        this.listDatesConso?.selectedItems.map(d => d.value))
        .subscribe((response: ResponseWrapper<ProduitDTO>) => {
          this.products = response.resultList;
          this.datasourceProducts = new DataSource({
            store: new ArrayStore({
              key: 'id',
              data: response.resultList,
            }),
            paginate: false
          });
        });
    }
  };

  closeDialog = (): void => {
    this.displayDialog = false;
    this.datasourceProducts = []
    this._equipesItems = []
    this.typeJourSelected = undefined;
    // Réinitialisation de la form pour y supprimer les sélections.
    this.initForm(new PlanProdPrintSupplier());
    this.onClose.emit(this.displayDialog);
  };

  updateEquipes = (data) => {
    this.equipesSelected = data.component.getSelectedRowsData("all");
    this.initEquipesSelectedForFichesTechniques();
    console.log("selectequipe", data.component.getSelectedRowsData("all"))
    if (data.component.getSelectedRowsData("all").length !== 0)
      this.initSubscriptionFichesTechniques()
    else
      this.datasourceProducts = []
  }

  printPlanProd = (): void => {
    this.initEquipesSelectedForFichesTechniques();
    this.gfs.validateAllFormFields(this.form);
    this.checkGroupingLines();

    let datesManufacture = [], consumptionDates = [];
    if (this.typeJourSelected === 'jourFab') {
      datesManufacture = this.listDatesFab.selectedItems.map(d => d.value);
      datesManufacture.map(d => this.utils.convertYYYYMMdd(d)).join(',');
      consumptionDates = [];
    } else {
      datesManufacture = [];
      consumptionDates = this.listDatesConso.selectedItems.map(d => d.value);
      consumptionDates.map(d => this.utils.convertYYYYMMdd(d)).join(',');
    }
    const idsTeams: number[] = this.form.controls['selectedEquipes'].value ? this.form.controls['selectedEquipes'].value.map(team => team.id).join(',') : [];
    const idsProductsToFilter = this.listPlats.selectedItems && this.listPlats.selectedItems.length ? this.listPlats.selectedItems.map(product => product.id) : this.products.map(p => p.id);

    if (!this.jourObligatoire && !this.warningGroupingLines) {
      this.productionSvc.printRecapFabPDF(this._planProd.id, datesManufacture, consumptionDates, idsTeams, idsProductsToFilter,
        this.form, this.form.controls['groupByPlc'].value, this.form.controls['groupByTeam'].value,
        this.form.controls['groupByMeal'].value, this.form.controls['groupByConsumptionDate'].value,
        FICHE_TECHNIQUE_PRINT_MODE.NORMAL,
        FICHE_TECHNIQUE_PRINT_MODE.PORTRAIT).subscribe(response => {
        const blob = new Blob([response], {type: 'application/pdf'});
        fs_saveAs(blob, `plan-production-${this._planProd.id}-${new Date().getTime()}.pdf`);
      });
    }

  };

  initEquipesSelectedForFichesTechniques = (): void => {
    const tmp = [...new Set(this.equipesSelected.filter(r => r.icon === 'pi pi-users').map(r => ({id: parseInt(r.data, 10)})))];
    this.form.controls["selectedEquipes"].setValue(tmp);
  };

  printFichesTechniquesClassique = (orientation: FICHE_TECHNIQUE_PRINT_MODE): void => {
    this.printFichesTechniques(FICHE_TECHNIQUE_PRINT_MODE.NORMAL, orientation);
  }

  printFichesTechniquesCondensee = (orientation: FICHE_TECHNIQUE_PRINT_MODE): void => {
    this.printFichesTechniques(FICHE_TECHNIQUE_PRINT_MODE.CONDENSEE, orientation);
  }

  /**
   * Impression des fiches techniques.
   */
  private printFichesTechniques = (mode: FICHE_TECHNIQUE_PRINT_MODE, orientation: FICHE_TECHNIQUE_PRINT_MODE): void => {
    this.initEquipesSelectedForFichesTechniques();
    this.gfs.validateAllFormFields(this.form);
    this.checkGroupingLines();

    let datesManufacture = [], consumptionDates = [];
    if (this.typeJourSelected === 'jourFab') {
      datesManufacture = this.listDatesFab.selectedItems.map(d => d.value);
      datesManufacture.map(d => this.utils.convertYYYYMMdd(d)).join(',');
      consumptionDates = [];
    } else {
      datesManufacture = [];
      consumptionDates = this.listDatesConso.selectedItems.map(d => d.value);
      consumptionDates.map(d => this.utils.convertYYYYMMdd(d)).join(',');
    }
    const idsTeams: number[] = this.form.controls['selectedEquipes'].value ? this.form.controls['selectedEquipes'].value.map(team => team.id).join(',') : [];
    const idsProductsToFilter = this.listPlats.selectedItems && this.listPlats.selectedItems.length ? this.listPlats.selectedItems.map(product => product.id) : this.products.map(p => p.id);

    if (!this.warningGroupingLines) {
      this.productionSvc.printFichesTechniques(this._planProd.id, datesManufacture, consumptionDates, idsTeams, idsProductsToFilter,
        this.form, this.form.controls['groupByPlc'].value, this.form.controls['groupByTeam'].value,
        this.form.controls['groupByMeal'].value, this.form.controls['groupByConsumptionDate'].value,
        mode, orientation).subscribe(response => {
          try {
            if (response.size) {
              const blob = new Blob([response], {type: 'application/pdf'});
              fs_saveAs(blob, `edition-fiche-technique.pdf`);
            } else {
              this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Aucune fiche technique ne correspond aux jours sélectionnés`);
            }
          } catch (exception) {
            this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Aucune fiche technique ne correspond aux jours sélectionnés`);
          }
        }
      );
    }
  };

  changeJourObligatoire = (): void => {
    const typeJour = this.typeJourSelected;
    let filterByConso = (typeJour !== 'jourFab');
    let listeDates: number[] = (typeJour === 'jourFab') ? this.listDatesFab?.selectedItems.map(d => d.value) : this.listDatesConso?.selectedItems.map(d => d.value);
    this.productionSvc.getByIdAndDatesPlanProduction(this._planProd.id, listeDates, filterByConso).subscribe(response => {
      let jourObligatoire = '';
      // Récupération des jours concernés
      let selectedJours: number[] = this.listDatesConso?.selectedItems.map(d => d.value);
      if (typeJour === 'jourFab')
        selectedJours = this.listDatesFab?.selectedItems.map(d => d.value);

      if (!selectedJours || !selectedJours.length) {
        const warning = `<i class="fas fa-warning"></i>`;
        jourObligatoire = typeJour === 'jourFab' ? `${warning} Veuillez sélectionner des jours de fabrication` : `${warning} Veuillez sélectionner des jours de consommation`;
        this._equipesItems = [];
        this.datasourceProducts = [];
      } else
        this._equipesItems = this.buildEquipesItems(response.resultList);
      this.jourObligatoire = jourObligatoire;
    });
  };

  /**
   * Indique s'il est possible d'imprimer
   */
  canPrint = (): boolean => {
    const typeJour = this.typeJourSelected;
    let selectedJours;

    if (typeJour == 'jourFab')
      selectedJours = this.listDatesFab?.selectedItems?.map(d => d.value);
    else
      selectedJours = this.listDatesConso?.selectedItems?.map(d => d.value);

    return this.equipesSelected && this.equipesSelected.length && selectedJours && selectedJours.length;
  };

  buildEquipesItems = (workshops: any[]): any => {
    let equipeItems: any = {data: []};
    if (workshops && workshops.length) {
      equipeItems = workshops.map(wk =>
        ({
          "label": wk.labelWorkshop,
          "data": wk.idWorkshop,
          "expandedIcon": "pi pi-cog",
          "collapsedIcon": "pi pi-cog",
          "children": wk.teams.map(team => ({
              "label": team.libelle,
              "data": team.id,
              "icon": "pi pi-users"
            })
          ),
          "expanded": true
        })
      );
    }
    return equipeItems;
  };


  changeSelectedJour(data: any) {
    this.typeJourSelected = data.itemData.value;
    this.changeJourObligatoire();
  }

  private initSelectedJour() {
    if (this.typesJours.length !== 0)
      this.typeJourSelected = this.typesJours[0].value;
    else
      this.typeJourSelected = null;
  }
}


export class PlanProdPrintSupplier {

  selectedTypeJour: TYPE_JOUR = 'jourFab';
  groupByTeam: boolean = true;
  groupByPlc: boolean = true;
  groupByMeal: boolean = true;
  groupByConsumptionDate: boolean = true;
  selectedJoursFab: Date[] = [];
  selectedJoursConso: Date[] = [];
  selectedEquipes: any[] = [];
  printMode: FICHE_TECHNIQUE_PRINT_MODE = FICHE_TECHNIQUE_PRINT_MODE.PAYSAGE;
  products: any[] = [];
}

export enum FICHE_TECHNIQUE_PRINT_MODE {
  PAYSAGE = 'PAYSAGE',
  PORTRAIT = 'PORTRAIT',
  CONDENSEE = 'CONDENSEE',
  NORMAL = 'NORMAL'
}
